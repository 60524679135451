<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Secretary information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <users-input
                      v-model="secretaryData.master.id"
                      @changeUser="(inputUser) => { secretaryData.master.id = inputUser.id }"
                      label="Secretary" />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <users-input
                      v-model="secretaryData.client.id"
                      @changeUser="(inputUser) => { secretaryData.client.id = inputUser.id }"
                      label="Customer" />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      v-if="brandList.length"
                      :items="brandList"
                      v-model="secretaryData.brand"
                      :item-text="(val) => { return val.name }"
                      clearable
                      item-value="id"
                      label="Brand"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      :items="['active', 'inactive']"
                      v-model="secretaryData.status"
                      label="Status"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import Request from '../../../../helpers/request'
import Brands from '../../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import UsersInput from '../../../../modules/components/UsersInput.vue'


export default {
  mixins: [Request, Brands],
  components: { UsersInput },
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      brandList: [],
      loading: true,
      secretaryData: {},
      deleteButtonLoader: false
    }
  },
  async beforeMount () {
    await this.requestData()

    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    requestData () {
      return new Promise((resolve) => {
        this.request('GET', `/secretaries/${this.$route.params.id}`, {}, ({ data }) => {
          this.secretaryData = data
          resolve()
        }, (loader) => { this.loading = loader })
      })
      
    },
    saveFunction () {
      let body = {
        data: {
          'master': (this.secretaryData.master && this.secretaryData.master.id) || this.secretaryData.master,
          'client': (this.secretaryData.client && this.secretaryData.client.id) || this.secretaryData.client,
          'status': this.secretaryData.status,
          'brand': (this.secretaryData.brand && this.secretaryData.brand.id) || this.secretaryData.brand
        }
      }

      this.request('PATCH', `/secretaries/${this.$route.params.id}`, body, async ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Secretary is updated'
        })

        this.$router.push('/tables/secretaries')
      })
    },
    moment (val) {
      return moment(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
