<template>
  <v-select
    v-model="userId"
    :items="items"
    :loading="loading"
    :disabled="disabled || loading"
    :item-text="(val) => { return getUserName(val)}"
    :outline="outline"
    item-value="id"
    :class="`full-width combobox-user` + classes"
    hide-details
    :clearable="clearable"
    :label="label"
    :multiple="multiple"
    @change="changeUser">
    <template v-slot:prepend-item>
        <div
        class="row px-3 py-1">
        <v-text-field
            v-model="searchUserName"
            filled
            placeholder="Search"
            hide-details
            @input="getUserListFetch"/>
        </div>
    </template>
  </v-select>
</template>

<script>
import request from '../../helpers/request'
import _debounce from 'lodash/debounce'

export default {
  mixins: [request],
  props: {
    brandList: {
      type: Array,
      default: () => { return [] }
    },
    classes: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number | String | null,
      default: null
    },
    label: {
      type: String,
      default: 'User'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    excludeIds: {
      type: Array,
      default: () => { return [] }
    },
    queryParams: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      searchUserName: '',
      items: [],
      loading: false,
      oldBrandList: this.brandList
    }
  },
  async beforeMount () {
    this.getUserList(this.brandListIds.join(','))

    // if (this.userPropId) {
    //   this.changeUser(this.userPropId)
    // }
  },
  computed: {
    brandListIds () {
      let ids = []
      try {
        ids = this.brandList.map(item => item.id || item)
      } catch (error) {}
      return ids
    },
    userId: {
      get () {
        return this.value ? parseInt(this.value) : this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    brandList: {
      handler (val) {
        if (this.oldBrandList.some(item => !val.includes(item))) {
          this.oldBrandList = val
          this.userId = null
          this.getUserList(this.brandListIds.join(','))
        }
      },
      deep: true
    },
    excludeIds: {
      handler (val) {
        if (this.excludeIds) {
          this.items = this.items.filter(item => !this.excludeIds.includes(item.id))
        }
      },
      deep: true
    }
  },
  methods: {
    reset () {
      this.userId = null
    },
    getUserName (val) {
      return val && `${val.firstName || ''} ${val.lastName || ''} id: ${val.id}`
    },
    getUserListFetch: _debounce(function (val) {
      if (val !== this.searchUserNameOld && val != null) {
        this.searchUserNameOld = val
        this.getUserList(this.brandListIds.join(','), val)
      }
    }, 500),
    getUserList (brandIds = null, search = null) {
      new Promise((resolve, reject) => {
        let url = '/users?pageSize=100'

        if (brandIds) {
          url += `&brands=${brandIds}`
        }

        if (search && search.length) {
          url += `&search=${search}`
        }

        this.loading = true

        this.request('GET', url, this.queryParams, async ({ data }) => {
          this.items = data.data

          if (this.userId && !search) {
            const currentUser = await this.getUser(this.userId)
            this.items = this.items.filter(user => user.id !== this.userId)
            this.items.unshift(currentUser)
          }

          if (this.excludeIds) {
            this.items = this.items.filter(item => !this.excludeIds.includes(item.id))
          }

          this.loading = false
          resolve()
        }, null, (error) => {
          this.loading = false
          reject(error)
        })
      })
    },
    changeUser (val) {
      let user

      if(this.multiple) {
        user = this.items.filter(item => item && val.includes(item.id))
      } else {
        user = this.items.find(item => item && item.id === val)
      }

      this.$emit('changeUser', user || null)
    },
    async getUser (id) {
      const { data } = await this.request('GET', `/users/${id}`, {})
      return data
    }
  }
}
</script>